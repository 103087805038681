export default {
  primary: {
    main: '#582ACB'
  },
  secondary: {
    main: '#1DAEFF'
  },
  info: {
    main: '#424242'
  }
}
